const rateLimiter = new Map();

const LIMITS = {
  'api': { max: 100, window: 900000 },    // 100 requests/15min
  'upload': { max: 10, window: 3600000 },  // 10 uploads/hour
  'auth': { max: 5, window: 900000 }       // 5 attempts/15min
};

export function checkRateLimit(userId, action = 'api') {
  const key = `${userId}_${action}`;
  const now = Date.now();
  
  const userLimit = rateLimiter.get(key) || { 
    count: 0, 
    resetAt: now + LIMITS[action].window 
  };
  
  if (now > userLimit.resetAt) {
    userLimit.count = 0;
    userLimit.resetAt = now + LIMITS[action].window;
  }

  userLimit.count++;
  rateLimiter.set(key, userLimit);

  console.log(`User: ${userId}, Action: ${action}, Count: ${userLimit.count}, Max: ${LIMITS[action].max}`);

  return userLimit.count <= LIMITS[action].max;
}
