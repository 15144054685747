import { registerSW } from 'virtual:pwa-register'

if ('serviceWorker' in navigator) {
  registerSW({
    onNeedRefresh() {
      // Add your logic for when a new version is available
      console.log('New version available')
    },
    onOfflineReady() {
      // Add your logic for when offline mode is ready
      console.log('App ready to work offline')
    },
  })
} 