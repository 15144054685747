<template>
  <footer class="bg-background border-t border-border py-4">
    <div class="container mx-auto px-4">
      <div class="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div class="text-sm text-muted-foreground order-2 sm:order-1">
          © {{ new Date().getFullYear() }} MemeStore. All rights reserved.
        </div>
        
        <div class="flex items-center space-x-4 order-1 sm:order-2">
          <a href="#" class="text-sm text-muted-foreground hover:text-foreground">
            Privacy
          </a>
          <a href="#" class="text-sm text-muted-foreground hover:text-foreground">
            Terms
          </a>
          <a href="#" class="text-sm text-muted-foreground hover:text-foreground">
            Contact
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import { ChevronUpIcon, ChevronDownIcon } from 'lucide-vue-next';

const isFooterVisible = ref(true); // Set to true by default to make the footer visible

const toggleFooter = () => {
  isFooterVisible.value = !isFooterVisible.value;
};
</script>

<style scoped>
.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
</style>