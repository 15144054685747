<script setup>
import { onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import TheHeader from './components/layout/TheHeader.vue'
import TheFooter from './components/layout/TheFooter.vue'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'

const router = useRouter()
const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)

onMounted(async () => {
  try {
    await userStore.fetchUser()
    if (!isLoggedIn.value && router.currentRoute.value.name !== 'About' && router.currentRoute.value.name !== 'Auth') {
      router.push('/')
    } else if (isLoggedIn.value && router.currentRoute.value.name === 'About') {
      router.push('/home')
    }
  } catch (error) {
    console.error('Error in App.vue onMounted:', error)
    router.push('/login')
  }
})

onMounted(() => {
  document.body.classList.add('prevent-shift')
})
</script>

<template>
  <div class="flex flex-col min-h-screen bg-background text-foreground">
    <TheHeader class="flex-shrink-0" />
    <main class="flex-1 overflow-y-auto pb-16" :class="[
      'mt-[calc(4rem+env(safe-area-inset-top))]',
      'sm:mt-16'
    ]">
      <div class="container mx-auto px-4 py-4 sm:py-8 max-w-7xl">
        <router-view></router-view>
      </div>
    </main>
    <TheFooter class="flex-shrink-0" />
  </div>
</template>

<style>
/* Global styles */
body {
  background-color: var(--color-background);
  color: var(--color-foreground);
  overflow-y: scroll; /* Allow vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

:root {
  --color-background: #ffffff;
  --color-foreground: #000000;
  --color-primary: #888d90;
  --color-border: #e2e8f0;
}

/* You can add a dark mode class to body or :root for theme switching */
.dark-mode {
  --color-background: #1a202c;
  --color-foreground: #ffffff;
  --color-primary: #888d90;
  --color-border: #2d3748;
}

/* Hide scrollbars for WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbars for Firefox */
* {
  scrollbar-width: none;
}

/* Hide scrollbars for IE and Edge */
* {
  -ms-overflow-style: none;
}

.prevent-shift {
  width: 100vw;
}
</style>