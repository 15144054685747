<template>
  <header class="fixed w-full top-0 z-50 bg-background border-b border-border">
    <div class="container mx-auto px-4">
      <div class="flex flex-col sm:flex-row items-center justify-between h-16">
        <!-- Logo and Nav Section -->
        <div class="flex items-center w-full sm:w-auto justify-between">
          <router-link to="/" class="flex items-center">
            <img :src="isDarkMode ? logoDark : logoLight" alt="MemeStore" class="h-8" />
          </router-link>
          
          <!-- Mobile menu button -->
          <button @click="isMenuOpen = !isMenuOpen" class="sm:hidden">
            <MenuIcon v-if="!isMenuOpen" class="h-6 w-6" />
            <XIcon v-else class="h-6 w-6" />
          </button>
        </div>

        <!-- Mobile menu backdrop -->
        <div 
          v-if="isMenuOpen"
          class="fixed inset-0 bg-background/80 backdrop-blur-sm z-[-1] sm:hidden"
          @click="isMenuOpen = false"
        ></div>

        <!-- Navigation and Search - Mobile Collapsible -->
        <div :class="[
          'w-full sm:w-auto',
          'flex flex-col sm:flex-row items-center gap-4',
          isMenuOpen ? 'block absolute top-16 left-0 right-0 bg-background/95 backdrop-blur-sm p-4 border-b border-border shadow-lg' : 'hidden sm:flex'
        ]">
          <!-- Add close button for mobile -->
          <button 
            v-if="isMenuOpen" 
            @click="isMenuOpen = false"
            class="absolute top-2 right-2 p-2 sm:hidden text-muted-foreground hover:text-foreground transition-colors"
            aria-label="Close menu"
          >
            <XIcon class="h-5 w-5" />
          </button>

          <!-- Navigation -->
          <nav class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <router-link
              v-for="item in navItems"
              :key="item.path"
              :to="item.path"
              class="px-3 py-2 text-sm hover:text-primary transition-colors"
            >
              {{ item.name }}
            </router-link>
          </nav>

          <!-- Search Bar -->
          <SearchBar class="w-full sm:w-auto max-w-xs" />

          <!-- User Actions -->
          <div class="flex items-center space-x-2">
            <Button @click="toggleDarkMode" variant="ghost" size="icon">
              <SunIcon v-if="isDarkMode" class="h-5 w-5" />
              <MoonIcon v-else class="h-5 w-5" />
            </Button>
            
            <!-- User Dropdown -->
            <div class="relative">
              <DropdownMenu v-if="isLoggedIn">
                <DropdownMenuTrigger as="div" class="inline-flex">
                  <Button variant="ghost" class="inline-flex items-center space-x-1 px-3">
                    <UserIcon class="h-5 w-5" />
                    <span class="max-w-[80px] truncate hidden sm:inline">{{ username }}</span>
                    <ChevronDownIcon class="h-4 w-4 ml-1" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent 
                  class="min-w-[8rem] absolute right-0"
                  :align="'end'"
                  :alignOffset="-5"
                  :sideOffset="5"
                >
                  <DropdownMenuItem as="div">
                    <router-link to="/profile" class="w-full">Profile</router-link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem as="div" @select="router.push('/manage')">
                    <span>Manage Memes</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem as="div" @click="logout">
                    Logout
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import SearchBar from '@/components/search/SearchBar.vue'
import { SunIcon, MoonIcon, UserIcon, ChevronDownIcon, MenuIcon, XIcon } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
import { useTheme } from '@/composables/useTheme'
import { useRoute } from 'vue-router'
import { useSearchStore } from '@/store/search'
import { ref } from 'vue'

const userStore = useUserStore()
const router = useRouter()
const logoLight = new URL('@/assets/logo-light.png', import.meta.url).href
const logoDark = new URL('@/assets/logo-dark.png', import.meta.url).href

const { isDarkMode, toggleDarkMode } = useTheme()

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'Favorites', path: '/favorites' },
  { name: 'Upload', path: '/upload' },
  { name: 'MemeWorld', path: '/global' },
]

const logout = async () => {
  await userStore.logout()
  router.push('/login')
}

const isLoggedIn = computed(() => userStore.isLoggedIn)
const username = computed(() => userStore.currentUser?.username || 'User')
const route = useRoute()
const searchStore = useSearchStore()
const isMenuOpen = ref(false)

onMounted(async () => {
  if (userStore.isLoggedIn) {
    await userStore.fetchUserProfile()
  }
})

watch(() => userStore.isLoggedIn, async (newValue) => {
  if (newValue) {
    await userStore.fetchUserProfile()
  }
})

watch(route, (newRoute) => {
  console.log('Route changed to:', newRoute.path)
  searchStore.clearSearchQuery()
})
</script>

<style scoped>
.pixel-box {
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.25rem;
}
</style>